import React from "react";
import styled from "styled-components";

const FRAME = styled.div`
    width: 100vw;
    height: calc(100vh - 64px);
    max-height: calc(100vh - 64px);

    position: relative;

    color: white;

    overflow: hidden;
`

const PageFrame = ({ children }) => (
    <FRAME>{children}</FRAME>    
)

export default PageFrame