import { makeStyles } from "@material-ui/core";
import React from "react";
import styled from 'styled-components'

const useStyle = makeStyles(theme => ({
    top:{
        backgroundColor:"#6c6c6c",
        height:"20%",
        color:"black",
        fontSize: "1.8rem",
        fontWeight:"700",
        borderBottom:"2px solid black",
        display:"flex",
        alignItems:"center",
        padding:"1.5rem 1.5rem 1.5rem 1.5rem",
    },
    content:{
        fontSize: "1.2rem",
        display:"flex",
        flexFlow:"column wrap",
        justifyContent:"center",
        padding:"1.5rem 1.5rem 3rem 1.5rem",
        
    },
}))

const Root = styled.div`
    width: 100%;
    background-color: #c5c5c5;
    ${props => props.fill&&"height: fit-content;"}
`


const InfoCard = (props) => {
    const {children, title, ...otherProps} = props
    const classes = useStyle()
    return(
        <Root {...otherProps}>
            <div className={classes.top}>
                {title}
            </div>
            <div className={classes.content}>
                {children}
            </div>
            
        </Root>
    )
}

export default InfoCard