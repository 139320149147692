import { Card, CardActionArea, CardContent, makeStyles } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const useStyles = makeStyles(theme => ({

    title: {
        color: '#FECE14',
        fontSize: '2.7vw',
        fontWeight: 'bold',
    },
    content: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
}))

const ButtonArea = styled(CardActionArea)`
    height: 100%;
    padding: 5px 5px;
`

const CardRoot = styled(Card)`
    background-color: #343234;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #ffffff40;
    justify-content: center;
    height: calc(100% - 10px);
    ${props => props.active && 'border: 5px #efcd14 solid;'}
`

const Head = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: absolute;

    transition: top 0.3s;
    ${props => props.active ? `
        top: 10%;
        ` : `
        top: calc(50% - 120px);
        `}
`

const Content = styled.div`
    color: white;
    padding: 0px 3.5vw;

    font-size: 1.6vw;
    font-weight: bold;
    text-align: justify;

    position: absolute;
    top: 49%;

    ${props => props.active ? `
        opacity: 1;
        transition: opacity 0.2s ease 0.3s;
        ` : `
        opacity: 0;
        transition: opacity 0.2s ease;
        `}
`

// position:"absolute",
//         bottom:"3rem",
//         zIndex:"5",
//         color: "white",
//         fontSize: "1.2rem",

const Tag = styled.div`
    color: white;
    padding: 0px 4vw;

    font-size: 1vw;
    text-align: justify;

    position: absolute;
    bottom: 3vw;

    ${props => props.active ? `
        opacity: 1;
        transition: opacity 0.2s ease 0.3s;
        ` : `
        opacity: 0;
        transition: opacity 0.2s ease;
        `}
`

const ServiceCard = props => {
    const { active, cardId, children, handleCardActive, icon, tag, title } = props
    const classes = useStyles()
    const handleClick = () => handleCardActive(cardId)
    return (
        <ButtonArea onClick={handleClick}>
            <CardRoot active={active}>
                <CardContent className={classes.content}>
                    <Head active={active}>
                        {icon}
                        <div className={classes.title}>{title}</div>
                    </Head>
                    <Content active={active}>{children}</Content>
                    <Tag active={active}> {tag} </Tag>
                </CardContent>
            </CardRoot>
        </ButtonArea>
    )
}

export default ServiceCard