import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MailIcon from '@material-ui/icons/Mail';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SendIcon from '@material-ui/icons/Send';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';


const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#c5c5c5',
        zIndex: 1,
        color: 'black',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: "#efcd14",
    },
    completed: {
        backgroundColor: "#efcd14",
    },
});
const style = {
    color: "#fff"
  };
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: '#efcd14',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#efcd14',
        },
    },
    line: {
        height: 10,
        backgroundColor: '#c5c5c5',
    },
})(StepConnector);

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    <div>01</div>
    const icons = { 
        1: <SendIcon />,
        2: <CallIcon />,
        3: <ReceiptIcon />,
        4: <MailIcon />,
        5: <ReceiptIcon />,
        6: <CheckCircleIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

function getSteps() {
    return ["已送出", "已連繫", "已確定合約", "已交件", "確認驗收", "結案"];
}

const RockSteppers = (props) => {
    const classes = useStyles();
    const [activeStep,] = React.useState(props.tstate);
    const steps = getSteps();

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
                style={{ backgroundColor: "transparent" }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon} ><div style={style}>{label}</div></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}


export default RockSteppers