import { IconButton } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const PageButton = props => {
    const handleClick = () => props.setPage(props.idx)
    return <IconButton {...props} onClick={handleClick} />
}

export default styled(PageButton)`
    background: #${props => props.active ? 'C4C4C4' : '6D6D6D'};
    &:hover {
        background: #${props => props.active ? '898989' : '4c4c4c'};
    }
`
