import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress, Grid, LinearProgress, makeStyles, MenuItem, TextField, withStyles } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from "react";
import req from '../../Component/API/api';
import Button from '../../Component/Button';
import Uploadfile from "../../Component/Uploadfile"
import "./checkmark.css";
import { Chip } from '@material-ui/core';
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: "1.3rem",
        borderRadius: "0.5rem",
        marginTop: "1.5rem",
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#efcd14',
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: "absolute",
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        paddingTop: "64px"
        ,
        backgroundColor: '#343234',
    },
    mainBlock: {
        width: "90%",
        height: "calc(90% - 64px)",
        position: "absolute",
        left: "5%",
        top: "calc(5% + 64px)",
    },
    mainBlockLeft: {
        width: "40%",
        height: "100%",
        backgroundColor: '#6D6D6D',
        position: "absolute",
        top: "0%",
        left: "0%"
        ,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    LeftTitle: {
        color: "#efcd14",
        fontSize: "4rem",
        fontWeight: 700,
        position: "absolute",
        top: "35%",
    },
    LeftDescription: {
        color: "#c5c5c5",
        fontSize: "1.2rem",
        position: "absolute",
        top: "55%",
        marginTop: "8%",
    },
    LeftEmail: {
        color: "#343234",
        fontSize: "1.2rem",
        fontWeight: 700,
        position: "absolute",
        bottom: "8%",
    },
    mainBlockRight: {
        width: "60%",
        height: "100%",
        backgroundColor: '#C4C4C4',
        position: "absolute",
        top: "0%",
        left: "40%",
    },
    RightInner: {
        width: "88%",
        height: "80%",
        position: "absolute",
        left: "6%",
        top: "10%",
    },
    RightTitle: {
        fontSize: "2.2vw",
        fontWeight: 700,
        color: "black",
    },
    GridContainer: {
        marginTop: theme.spacing(3),
        zIndex: 10,
    },
    stepButtons: {
        position: "absolute",
        bottom: "0px",
        width: '100%',
        marginTop: theme.spacing(3),
        display: 'inline-flex',
        justifyContent: 'center',
        columnGap: theme.spacing(2),
    },
    circularContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Context: {
        minHeight: "10vh",
    },
    circular: {
        width: "100%",
        height: "100%",
    },
    OKContainer: {
        width: "88%",
        height: "80%",
        position: "absolute",
        left: "6%",
        top: "10%",
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    OKTitle: {
        fontSize: "4rem",
        fontWeight: 700,
        color: "black",
    },
    OKDescript: {
        color: "#6c6c6c",
        fontSize: "1.2rem",
    },
    OKButton: {
        position: "absolute",
        bottom: "10%",
    },
    notchedOutline: {
        borderWidth: "2px",
        borderColor: "#343234 !important"
    },
    center_block: {
        width: "200%",
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
    },
}))



function ToNumber(x) {
    const parsed = parseInt(x, 10);
    if (isNaN(parsed)) { return 0; }
    return parsed
}

const Contact = (props) => {
    const classes = useStyles()
    const [step, setStep] = useState(1)
    const [selectedDate, handleDateChange] = useState(new Date());
    const [form, setForm] = useState({})
    const [files, setFiles] = useState([])
    const handlePrevStep = () => setStep(prevStep => prevStep - 1)
    const handleNextStep = () => setStep(prevStep => {
        if (prevStep === 1) {
            // 檢查 last_name, first_name, gender, phone, mail 是不是空的
            if (Object.keys(form).length < 5 || form.last_name === "" || form.first_name === "" || form.gender === "" || form.phone === "" || form.mail === "") {
                alert("所有欄位都必須要填寫")
                return prevStep
            }
        } else if (prevStep === 2) {
            // 檢查 unit, job, budget, category, contact 是不是空的
            form.budget = ToNumber(form.budget)
            if (form.budget <= 0) {
                alert("預算必須為正整數")
                return prevStep
            }
            if (Object.keys(form).length < 10 || form.unit === "" || form.job === "" || form.category === "" || form.contact === "") {
                alert("所有欄位都必須要填寫")
                return prevStep
            }
        }
        return prevStep + 1
    })

    const handleCommit = () => {
        if (form.deadline === undefined) {
            form.deadline = new Date()
        }
        if (Object.keys(form).length < 13 || form.title === "" || form.content === "" || form.deadline === "") {
            alert("所有欄位都必須要填寫")
            return
        }

        setStep(-1)
        // After POST success

        //Build a form
        const requestForm = new FormData()
        requestForm.append("first_name", form.first_name)
        requestForm.append("last_name", form.last_name)
        requestForm.append("gender", form.gender)
        requestForm.append("unit", form.unit)
        requestForm.append("job", form.job)
        requestForm.append("phone", form.phone)
        requestForm.append("mail", form.mail)
        requestForm.append("contact", form.contact)
        requestForm.append("title", form.title)
        requestForm.append("content", form.content)
        requestForm.append("deadline", form.deadline)
        requestForm.append("budget", form.budget)
        requestForm.append("category", form.category)
        files.forEach(file => {
            requestForm.append("file", file)
        })

        console.log("request form", requestForm)
        req.post("/task", requestForm, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((res) => {
                setTimeout(() => {
                    setStep(0)
                }, 1.5);
                console.log("uuid is", res.data.uuid)
            }).catch((err) => {
                console.log(err.response)
                alert("網路異常")
                // window.location.reload()
                setStep(3)
            })
    }
    const phone_val = (event) => {
        if (event.target.value.match(/((?=(09))[0-9]{10})$/g)) {
            setphoneInputcolor('success')
            setphoneerrorText('correct !')
        } else {
            setphoneInputcolor('warning')
            event.target.value === "" ? setphoneerrorText('') : setphoneerrorText('Invalid format: 09######## !')
        }
    }
    const email_val = (event) => {
        if (event.target.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm)) {
            setemailInputcolor('success')
            setemailerrorText('correct !')
        } else {
            setemailInputcolor('warning')
            event.target.value === "" ? setemailerrorText('') : setemailerrorText('Invalid format!')
        }
    }
    // STEP 1
    const handleLastNameChange = evt => setForm(prevForm => ({ ...prevForm, last_name: evt.target.value }))
    const handleFirstNameChange = evt => setForm(prevForm => ({ ...prevForm, first_name: evt.target.value }))
    const handleGenderChange = evt => setForm(prevForm => ({ ...prevForm, gender: evt.target.value }))
    const handlePhoneChange = evt => { setForm(prevForm => ({ ...prevForm, phone: evt.target.value })); phone_val(evt) }
    const handleMailChange = evt => { setForm(prevForm => ({ ...prevForm, mail: evt.target.value })); email_val(evt) }
    // STEP 2   
    const handleUnitChange = evt => setForm(prevForm => ({ ...prevForm, unit: evt.target.value }))
    const handleOccupationChange = evt => setForm(prevForm => ({ ...prevForm, job: evt.target.value }))
    const handleBudgetChange = evt => setForm(prevForm => ({ ...prevForm, budget: evt.target.value }))
    const handleCategoryChange = evt => setForm(prevForm => ({ ...prevForm, category: evt.target.value }))
    const handleDeadlineChange = DL => {
        handleDateChange(DL)
        setForm(prevForm => ({ ...prevForm, deadline: DL }))
    }
    const [phoneinputcolor, setphoneInputcolor] = useState('info');
    const [phoneerrorText, setphoneerrorText] = useState('');
    const [emailinputcolor, setemailInputcolor] = useState('info');
    const [emailerrorText, setemailerrorText] = useState('');
    // const [phone_val, setphone_val] = useState('');
    const handleContactTimeChange = evt => setForm(prevForm => ({ ...prevForm, contact: evt.target.value }))
    // STEP 3
    const handleTitleChange = evt => setForm(prevForm => ({ ...prevForm, title: evt.target.value }))
    const handleContentChange = evt => setForm(prevForm => ({ ...prevForm, content: evt.target.value }))
    return (
        <div className={classes.root}>
            <div className={classes.mainBlock}>
                <div className={classes.mainBlockLeft}>
                    <div className={classes.LeftTitle}>Contact us</div>
                    <div className={classes.LeftDescription}>工作室將會在三天內回覆</div>
                    <div className={classes.LeftEmail}>工作室信箱：studio@threerocks.work</div>
                </div>
                <div className={classes.mainBlockRight}>
                    {
                        step > 0 &&
                        <div className={classes.RightInner}>
                            <div className={classes.RightTitle}>
                                {step === 1 && "聯絡資料"}
                                {step === 2 && "委託資訊"}
                                {step === 3 && "委託內容"}
                            </div>
                            <BorderLinearProgress variant="determinate" value={100 / 3 * step} />
                            {
                                step === 1 &&
                                <Grid container spacing={9} className={classes.GridContainer}>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="姓氏" value={form.last_name} onChange={handleLastNameChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="名字" value={form.first_name} onChange={handleFirstNameChange} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField variant="outlined" fullWidth select required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="性別" value={form.gender || ''} onChange={handleGenderChange}>
                                            <MenuItem value="male">男</MenuItem>
                                            <MenuItem value="female">女</MenuItem>
                                            <MenuItem value="other">其他</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            helperText={phoneerrorText}
                                            color={phoneinputcolor}
                                            label="手機" type="phone" value={form.phone} onChange={handlePhoneChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField variant="outlined" fullWidth type="email" required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            helperText={emailerrorText}
                                            color={emailinputcolor}
                                            label="Email" value={form.mail} onChange={handleMailChange} />
                                    </Grid>
                                </Grid>
                            }
                            {
                                step === 2 &&
                                <Grid container spacing={9} className={classes.GridContainer} >
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="工作單位" value={form.unit} onChange={handleUnitChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="職稱" value={form.job} onChange={handleOccupationChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth placeholder="ex: 10000" required
                                            InputProps={{
                                                classes: { notchedOutline: classes.notchedOutline, },
                                                endAdornment: <InputAdornment position="end">TWD</InputAdornment>
                                            }}
                                            label="預算" defaultValue={0} type="number" value={form.budget} onChange={handleBudgetChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth select required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="服務種類" value={form.category || ''} onChange={handleCategoryChange}>
                                            <MenuItem value="system">系統設計</MenuItem>
                                            <MenuItem value="web">網頁設計與部屬</MenuItem>
                                            <MenuItem value="auto">自動化流程</MenuItem>
                                            <MenuItem value="ml">機器學習應用開發</MenuItem>
                                            <MenuItem value="app">app應用開發</MenuItem>
                                            <MenuItem value="other">其他</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined" fullWidth required
                                            placeholder="ex: 星期一三五中午"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                }
                                            }}
                                            label="方便聯絡時間" value={form.contact} onChange={handleContactTimeChange} />
                                    </Grid>

                                </Grid>
                            }
                            {
                                step === 3 &&
                                <Grid container spacing={2} className={classes.GridContainer}>
                                    <Grid item xs={12}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            label="委託標題" value={form.title} onChange={handleTitleChange} />
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} sx={{ height: 56 }}>
                                            <KeyboardDatePicker
                                                inputVariant="outlined"
                                                fullWidth
                                                autoOk
                                                disablePast
                                                margin="normal"
                                                label="期望交件日期"
                                                format="yyyy/MM/dd"
                                                value={selectedDate}
                                                onChange={handleDeadlineChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: classes.notchedOutline,
                                                    }
                                                }}
                                            />
                                            {/* https://stackoverflow.com/questions/66300576/material-ui-how-to-align-different-form-elements-including-pickers-in-a-row */}
                                        </MuiPickersUtilsProvider>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField variant="outlined" fullWidth required
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            multiline minRows={8} maxRows={8}
                                            label="內文" value={form.content} onChange={handleContentChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Uploadfile onSaveFiles={setFiles} />
                                    </Grid>
                                    <div style={{ display: "inline-flex", gap: "5px" }}>
                                        {
                                            files.map(file =>
                                                <Chip
                                                    key={file.name}
                                                    size="large"
                                                    label={file.name}
                                                    onDelete={() => {
                                                        var array = [...files]
                                                        var index = array.indexOf(file);
                                                        if (index !== -1) {
                                                            array.splice(index, 1);
                                                            setFiles(array)
                                                        }
                                                    }} />
                                            )
                                        }
                                    </div>
                                </Grid>
                            }
                            <div className={classes.stepButtons}>
                                {step !== 1 && <Button bgcolor="#efcd14" fgcolor="black" hcolor="#a8900e"
                                    onClick={handlePrevStep}>上一步</Button>}
                                {step === 1 && <Button bgcolor="#6c6c6c" fgcolor="black" hcolor="#343234" disabled>上一步</Button>}
                                {step !== 3 && <Button bgcolor="#efcd14" fgcolor="black" hcolor="#a8900e"
                                    onClick={handleNextStep}>下一步</Button>}
                                {step === 3 && <Button bgcolor="#efcd14" fgcolor="black" hcolor="#a8900e"
                                    onClick={handleCommit}>送出</Button>}
                            </div>
                        </div>
                    }
                    {
                        step === -1 &&
                        <div className={classes.RightInner}>
                            <div className={classes.circularContainer}>
                                <CircularProgress size={80} />
                            </div>
                        </div>
                    }
                    {
                        // failed request
                        step === -2 &&
                        <div>
                        </div>
                    }
                    {
                        step === 0 &&
                        <div className={classes.OKContainer}>
                            <div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            </div>
                            <div className={classes.OKTitle}>
                                委託已送出
                            </div>
                            <div className={classes.OKDescript}>
                                請記得至email查收信件
                            </div>
                            <Button bgcolor="#efcd14" fgcolor="black" hcolor="#a8900e" className={classes.OKButton}
                                href="/">確認</Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Contact