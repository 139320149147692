import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme=>({
    copyRight:{
        color:"#fff",
        textAlign: "center",
        marginBottom:"5vh"
    },
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <div className={classes.copyRight}>
            Copyright © 2021 Threerocks Studio, All Rights Reserved.
        </div>
    )
}

export default Footer