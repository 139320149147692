import React from "react"
import { Button as MuiButton } from "@material-ui/core"
import styled from "styled-components"

const StyledButton = styled(MuiButton)`
    padding: 2px 24px;
    border-radius: 1.2rem;
    background-color: ${props => props.bgcolor ? props.bgcolor : '#6D6D6D'};
    color: ${props => props.fgcolor ? props.fgcolor : 'white'};
    font-size: ${props => props.fontSize ? props.fontSize : "1.125rem"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "bold"};
    &:hover{
        background-color: ${props => props.hcolor ? props.hcolor : '#5e5e5e'};
    }
`

const Button = props => {
    const { children, href, ...otherProps } = props
    return <StyledButton {...otherProps} href={href}>{children}</StyledButton>
}

export default Button