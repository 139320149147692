import { CardActionArea } from "@material-ui/core";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { IconButton } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { green } from "@material-ui/core/colors";
import { AirlineSeatReclineNormalTwoTone } from "@material-ui/icons";

const ButtonArea = styled(CardActionArea)`
    width: 100%;
    display: block;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.isActive ? "#efcd14" : "#c5c5c5"};
    font-size: 2.8vh;
    font-weight:600 ;
    padding-left: 1vh;
`

const FadeIn = keyframes`
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
`

const Content = styled.div`
    display: block;
    
    font-size: 2vh;
    margin-left: 4vh;
    margin-top: 4vh;
    margin-bottom: 4vh;

    text-align: justify;
    color: white;

    animation: ${FadeIn} 1.2s ease
`

const Line = styled.div`
    width: 100%;
    border-bottom: solid 2px #6c6c6c;
    margin: 1vh auto 2vh auto
`

const ExpandArrow = styled(ArrowRightIcon)`
    color: ${props => props.isActive ? "#efcd14" : "#c5c5c5"};
    font-size: 4vh;
    transition: all 0.3s ease-out;
    transform: rotate(0deg);
    ${({isActive}) => isActive && `transform: rotate(90deg)`}
}
`


const Expander = (({ title, children }) => {
    const [isActive, setIsActive] = useState(false)

    const handleClick = () => setIsActive((isActive)=>(!isActive))
    return (
        <div>
            <ButtonArea onClick={handleClick} rotate={isActive}>
                <Title isActive={isActive}>
                    <IconButton aria-label="arrow">
                        <ExpandArrow isActive={isActive}/>
                    </IconButton>
                    {title}
                </Title>
            </ButtonArea>
            {
                isActive && <Line />
            }
            {
                isActive &&
                <Content>
                    {children}
                </Content>
            }
            <Line />

        </div>
    )
})

export default Expander