import { IconButton, makeStyles } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import AboutFrame from "./Component/AboutFrame";
import "./animate.css";

const Carousel = styled.div`
  position: relative;
  display: inline-flex;
  left: calc(${(props) => -props.page * 100}%);
  transition: left 0.3s ease;
`;
const Label = styled.div`
  position: absolute;
  top: 82.5%;
  left: 11%;
  width: 7%;
  height: 1.5%;
  background-color: #6c6c6c;
  border-radius: 40px;
  z-index: 20;
  ${(props) =>
    props.page === 2 &&
    `
    background-color: #efcd14; 
    transition: all 1s;
`}
  transition: left 0.3s ease;
`;
const Label2 = styled.div`
  position: absolute;
  top: 82.5%;
  left: 19%;
  width: 7%;
  height: 1.5%;
  background-color: #6c6c6c;
  border-radius: 40px;
  z-index: 20;
  ${(props) =>
    props.page === 3 &&
    `
    background-color: #efcd14; 
    transition: all 1s;
  `}
  transition: left 0.3s ease;
`;
const Label3 = styled.div`
  position: absolute;
  top: 82.5%;
  left: 27%;
  width: 7%;
  height: 1.5%;
  background-color: #6c6c6c;
  border-radius: 40px;
  z-index: 20;
  ${(props) =>
    props.page === 4 &&
    `
    background-color: #efcd14; 
    transition: all 1s;
  `}
  transition: left 0.3s ease;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#343234",
    width: "100vw",
    height: "calc(100vh - 64px)",
    paddingTop: "64px",
    overflow: "hidden",
    userSelect: "none",
  },
  pageOne: {
    position: "relative",
    width: "100vw",
  },
  pageOneMain1: {
    color: "#efcd14",
    fontSize: "11.5vh",
  },
  pageOneMain2: {
    color: "#c5c5c5",
    fontSize: "4vh",
  },
  pageOneBottomText: {
    position: "absolute",
    top: "57%",
    left: "5%",
    overflow: "hidden",
    fontWeight: "bold",
    fontSize: "35vh",
    transition: "all 1s",
    color: "#343234",
    textShadow:
      "4px 4px 0 #c5c5c5,4px -4px 0 #c5c5c5,-4px 4px 0 #c5c5c5,-4px -4px 0 #c5c5c5",
  },
  prev: {
    color: "#6c6c6c",
    width: "48px",
    height: "48px",
  },
  prevButton: {
    position: "fixed",
    top: "50%",
    left: "1%",
    zIndex: 1000,
  },
  next: {
    color: "#6c6c6c",
    width: "48px",
    height: "48px",
  },
  nextButton: {
    position: "fixed",
    top: "50%",
    right: "1%",
    zIndex: 1000,
  },
  stone1: {
    position: "absolute",
    top: "20%",
    right: "16%",
    width: "30%",
  },
  stone2: {
    position: "absolute",
    width: "30%",
    right: "17%",
    top: "18%",
  },
  stone3: {
    position: "absolute",
    top: "11%",
    width: "27%",
    right: "19%",
  },
  Jimmy: {
    position: "absolute",
    top: "10%",
    right: "15%",
    height: "72%",
    zIndex: 1,
  },
  Alan: {
    position: "absolute",
    top: "10%",
    right: "15%",
    height: "72%",
    zIndex: 1,
  },
  Ruting: {
    position: "absolute",
    top: "10%",
    right: "15%",
    height: "72%",
    zIndex: 1,
  },
  // introducing
  sloganSecond: {
    position: "absolute",
    top: "10%",
    left: "10%",
    userSelect: "none",
  },
  sloganSecondText: {
    color: "#FECE14",
    fontWeight: "bold",
    fontSize: "4.5vw",
    marginBottom: theme.spacing(2),
    userSelect: "none",
  },
  ContentSecond: {
    color: "white",
    fontSize: "2vw",
    position: "absolute",
    left: "50%",
    bottom: "18%",
    userSelect: "none",
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const N_PAGE = 5;
  const [page, setPage] = React.useState(0);

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };
  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, N_PAGE - 1));
  };

  return (
    <div className={classes.root}>
      {page !== 0 && page!==1 && (
        <div className={classes.leftArrow}>
          <IconButton className={classes.prevButton} onClick={handlePrevPage}>
            <ArrowBackIos className={classes.prev} viewBox="-4 0 20 24" />
          </IconButton>
        </div>
      )}
      {page !== 0 && page!==1 && (
        <div>
          <Label page={page} />
          <Label2 page={page} />
          <Label3 page={page} />
        </div>
      )}
      {page !== N_PAGE - 1 && (
        <div className={classes.rightArrow}>
          <IconButton className={classes.nextButton} onClick={handleNextPage}>
            <ArrowForwardIos className={classes.next} />
          </IconButton>
        </div>
      )}
      <Carousel page={page}>
        {/* <Contact_page1 /> */}
        <div className={classes.pageOne} page={page}>
          {/* GROUP PICTURES */}
          <img src="jimmy.svg" class="JimmyGroup" alt="" />
          <img src="alan.svg" class="AlanGroup"  alt="" />
          <img src="ruting.svg" class="RutingGroup" alt="" />
          <div class="pageOneMain">
            <div className={classes.pageOneMain1}>Our Team</div>
            <div className={classes.pageOneMain2}>Walking on water needs to know <br/>where the rocks are.</div>
          </div>
          <div className={classes.pageOneBottomText}>Our Team</div>
        </div>
        {/* 團隊介紹 */}
        <div  className={classes.pageOne} page={page}>
          <div className={classes.sloganSecond}>
            <div className={classes.sloganSecondText}> Threerocks </div>
            <div className={classes.sloganSecondText}> Studio </div>
          </div>
          <div className={classes.ContentSecond}>
            正在電腦前的您，<br />
            是否渴望提升網站的流量?<br />
            是否厭倦了重複的例行性工作?<br />
            是否想要打造屬於自己的品牌?<br />
            我們是 Threerocks studio 團隊<br />
            我們會為您打造專屬的資訊解決方案，<br />
            幫助您省下更多的時間!<br />
            幫助您更有效率的達成目標!<br />
          </div>
        </div>
        <AboutFrame
          name="Jimmy"
          job="Engineer"
          page={page}
          stone={
            <img
              src="./rock_bottom_yellow.svg"
              className={classes.stone1}
              alt=""
            />
          }
          portrait={<img src="jimmy.svg" className={classes.Jimmy} alt="" />}
        >
          Intel Software Engineer intern CCE DBG
          <br />
          多個 App store 上架軟體開發經驗
          <br />
          2021 TED x NYCU 資訊技術指導
        </AboutFrame>
        <AboutFrame
          name="Alan"
          job="Engineer"
          page={page}
          stone={
            <img
              src="./rock_middle_yellow.svg"
              className={classes.stone2} 
              alt=""
            />
          }
          portrait={<img src="alan.svg" className={classes.Alan} alt="" />}
        >
          強化學習實驗室-REAL大學部研究生
          <br />
          陽明交通大學 ITSC開發團隊 - 校務系統開發
          <br />
          管理科學系助教 - 機器學習&系統開發
        </AboutFrame>
        {/* "https://i.imgur.com/P8KQ8dX.png" */}
        <AboutFrame
          name="Ruting"
          job="Designer"
          page={page}
          stone={
            <img
              src="./rock_top_yellow.svg"
              className={classes.stone3}
              alt=""
            />
          }
          portrait={<img src="ruting.svg" className={classes.Ruting} alt="" />}
        >
          2021 TED x NYCU網頁設計
          <br />
          陽明交通大學校內活動文宣設計
          <br />
          跨域學程編輯宣傳小組
        </AboutFrame>
      </Carousel>
    </div>
  );
};

export default AboutUs;
