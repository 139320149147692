import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Component/Navbar";
import About from "./Page/About";
import Contact from "./Page/Contact";
import Homepage from "./Page/Homepage";
import Task from "./Page/Task";
import Service from "./Page/Service";
import AppDesign from "./Page/Service/AppDesign";
import WebDesign from "./Page/Service/WebDesign";
import AutoDesign from "./Page/Service/AppDesign";
import Question from "./Page/Question"
const App = () => {
    return (
        <BrowserRouter>
            {
                !window.location.pathname.startsWith("/task") && <Navbar />
            }
            <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route exact path="/about" element={<About />} />
                {/* Services */}
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/service/app_design" element={<AppDesign />} />
                <Route exact path="/service/web_design" element={<WebDesign />} />
                <Route exact path="/service/auto_design" element={<AutoDesign />} />
                {/* Question */}
                <Route exact path="/question" element={<Question />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/task/:uuid" element={<Task />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
