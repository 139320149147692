import { makeStyles } from "@material-ui/core";
import React from "react";
import Button from "../../Component/Button"
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'hidden',
        paddingTop: 64,
        background: '#343234',
        userSelect:"none",
    },
    title: {
        display: "block",
        margin: "8vh auto 8vh auto",
        textAlign: "center"
        ,
        color: "white",
        fontWeight: "bold",
        fontSize: "12vh",
    },
    intro: {
        position: "relative",
        margin: "20vh 10vw 20vh 10vw",
        height: "30vh",
        width: "80vw",
        color: "white",
    },
    pic: {
        position: "absolute",
        width: "30vh",
        paddingLeft: "10vh",
    },
    descript: {
        position: "absolute",
        width: "72%",
        height: "auto",
        left: "30%",
    },
    descriptTitle: {
        position: "absolute",
        color: "#efcd14",
        fontSize: "5vh",
        fontWeight: "500",
    },
    descriptTag: {
        position: "absolute",
        fontSize: "0.8vw",
        top: "6.8vh",
        left: "0.3vh",
    },
    descriptContent: {
        position: "absolute",
        fontSize: "1.3vw",
        top: "13vh",
    },
    descriptButton: {
        position: "absolute",
        top: "25vh",
    },
    onlyThreeSteps: {
        position: "relative",
        background: "#c5c5c5",
        height: "85vh",
        color: "black",
    },
    onlyThreeStepsTitle: {
        paddingTop: "8vh",
        textAlign: "center",
        fontSize: "8vh",
        fontWeight: "700",
    },
    circle1: {
        position: "absolute",
        top: "30vh",
        left: "21vw",
        height: "20vh",
        width: "20vh",
        borderRadius: "50%",
        background: "#efcd14"
        ,
        color: "black",
        fontSize: "10vh",
        textAlign: "center",
        lineHeight: "20vh",
        zIndex: "1"
    },
    circle2: {
        position: "absolute",
        top: "30vh",
        left: "46vw",
        height: "20vh",
        width: "20vh",
        borderRadius: "50%",
        background: "#efcd14"
        ,
        color: "black",
        fontSize: "10vh",
        textAlign: "center",
        lineHeight: "20vh",
        zIndex: "1"
    },
    circle3: {
        position: "absolute",
        top: "30vh",
        left: "71vw",
        height: "20vh",
        width: "20vh",
        borderRadius: "50%",
        background: "#efcd14",
        zIndex: "1"
        ,
        color: "black",
        fontSize: "10vh",
        textAlign: "center",
        lineHeight: "20vh",
    },
    line: {
        position: "absolute",
        top: "39vh",
        left: "25vw",
        background: "#efcd14",
        width: "50vw",
        height: "1vh",
    },
    text1: {
        position: "absolute",
        top: "48vh",
        left: "21vw",
        height: "20vh",
        width: "20vh"
        ,
        fontSize: "8vh",
        textAlign: "center",
        lineHeight: "20vh",
        zIndex: "1"
    },
    text2: {
        position: "absolute",
        top: "48vh",
        left: "46vw",
        height: "20vh",
        width: "20vh"
        ,
        fontSize: "8vh",
        textAlign: "center",
        lineHeight: "20vh",
        zIndex: "1"
    },
    text3: {
        position: "absolute",
        top: "48vh",
        left: "71vw",
        height: "20vh",
        width: "20vh",
        zIndex: "1"
        ,
        fontSize: "8vh",
        textAlign: "center",
        lineHeight: "20vh",
    },
    gogo: {
        position: "absolute",
        left: "45.5vw",
        bottom: "5vh",
    },
}))

const Service = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.title}>Our Services</div>
            {/* 系統設計 */}
            {/* <div className={classes.intro}>
                <img src="/square.png" className={classes.pic} alt="" />
                <div className={classes.descript}>
                    <div className={classes.descriptTitle}>
                        系統設計
                    </div>
                    <div className={classes.descriptTag}>
                        #商店POS機系統客製化設計 #自動化流程設計
                    </div>
                    <div className={classes.descriptContent}>
                        市面上有許多現成的系統，高昂的授權費加上地靈活度的設計並不適合每個企業使用。我們可以根據顧客需求客製化一套方便易用的系統，不用再付授權費，一勞永逸的方法。
                    </div>
                    <div className={classes.descriptButton} >
                        <Button
                            href="/service/system_design" fontSize="1.2vw"
                            bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">深入了解</Button>
                    </div>
                </div>
            </div> */}
            {/* 網頁設計與部屬 */}
            <div className={classes.intro}>
                <img src="/svgs/web/webdesign.svg" className={classes.pic} alt="" />
                <div className={classes.descript}>
                    <div className={classes.descriptTitle}>
                        網頁設計與部屬
                    </div>
                    <div className={classes.descriptTag}>
                        #商業網站設計 #網頁伺服器架設 #前後端設計
                    </div>
                    <div className={classes.descriptContent}>
                        常常覺得為什麼競爭對手的曝光率永遠比自己高嗎？研究顯示一個好的門面會決定顧客對於企業整體形象的80%，一個好的網站就顯得極其重要。
                    </div>
                    <div className={classes.descriptButton} >
                        <Button
                            href="/service/web_design" fontSize="1.2vw"
                            bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">深入了解</Button>
                    </div>
                </div>
            </div>
            {/* 自動化流程 */}
            <div className={classes.intro}>
                <img src="/svgs/auto/auto.svg" className={classes.pic} alt="" />
                <div className={classes.descript}>
                    <div className={classes.descriptTitle}>
                        自動化流程
                    </div>
                    <div className={classes.descriptTag}>
                        #自動化工具設計與開發
                    </div>
                    <div className={classes.descriptContent}>
                        每次都要重複一樣的步驟很煩嗎？浪費人力資源的同時也是增加出錯的機率。自動化流程可以讓整體更有效率，節省公司人力資源完成，最小化出錯機率。
                    </div>
                    <div className={classes.descriptButton} >
                        <Button
                            href="/service/auto_design" fontSize="1.2vw"
                            bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">深入了解</Button>
                    </div>
                </div>
            </div>
            
            
            {/* app應用開發 */}
            <div className={classes.intro}>
                <img src="/svgs/app/app.svg" className={classes.pic} alt="" />
                <div className={classes.descript}>
                    <div className={classes.descriptTitle}>
                        app應用開發
                    </div>
                    <div className={classes.descriptTag}>
                        #ios app開發 #android平台開發 #網頁型app開發
                    </div>
                    <div className={classes.descriptContent}>
                        為了確保顧客的第一選擇就是你，許多店家會發行會員卡。但在這手機不離身得時代，有一個app在手機裡就相當於一張24小時的會員卡直接給顧客。
                    </div>
                    <div className={classes.descriptButton} >
                        <Button
                            href="/service/app_design" fontSize="1.2vw"
                            bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">深入了解</Button>
                    </div>
                </div>
            </div>
            {/* 只要三步驟 */}
            <div className={classes.onlyThreeSteps}>
                <div className={classes.onlyThreeStepsTitle}>只要三步驟，解決問題</div>
                <div className={classes.circle1}>01</div>
                <div className={classes.circle2}>02</div>
                <div className={classes.circle3}>03</div>
                <div className={classes.text1}>諮詢</div>
                <div className={classes.text2}>執行</div>
                <div className={classes.text3}>結案</div>
                {/* <div className={classes.line} /> */}
                <div className={classes.gogo} >
                    <Button
                        href="contact" fontSize="2vw"
                        bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">馬上諮詢</Button>
                </div>
            </div>
        </div>
    )
}

export default Service