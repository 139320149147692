import React, { Component } from "react";
import { DropzoneDialog  } from "material-ui-dropzone";
import { Button } from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';


const MB = 1000*1000


export default class Uploadfile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            files: []
        }
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    handleSave(files) {
        this.setState({
            file: files,
            open: false,
        })
        console.log(files)
        this.props.onSaveFiles(files)
    }

    handleOpen() {
        this.setState({
            open: true
        })
    }

    getFileRemovedMessage(fileName){
        return `檔案${fileName}已經被移除`
    }

    getFileAddedMessage(fileName){
        return `添加${fileName}\n`
    }
    getFileLimitExceedMessage(filesLimit){
        return `檔案數量不可以超過${filesLimit}`
    }
    render() {

        return (
            <div>
                <Button variant="contained" startIcon={<BackupIcon />} onClick={this.handleOpen.bind(this)} color="primary">
                    上傳檔案
                </Button>
                <DropzoneDialog
                    height="100%"
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    showPreviews={true}
                    showFileNames={true}
                    filesLimit={10}
                    maxFileSize={10*MB}
                    dialogTitle="上傳您的檔案"
                    previewText="預覽"
                    cancelButtonText="取消"
                    submitButtonText="上傳"
                    getFileRemovedMessage={this.getFileRemovedMessage}
                    getFileAddedMessage={this.getFileAddedMessage}
                    getFileLimitExceedMessage={this.getFileLimitExceedMessage}
                    onClose={this.handleClose.bind(this)}
                    dropzoneText="將檔案拖曳至此上傳"
                />
            </div>
        )
    }
}