import { makeStyles } from "@material-ui/core";
import React from "react";
import Button from "../../../Component/Button";

const BackGroundImagePath = '/svgs/app/app_big.svg'
const HaveAnyImagePath = '/svgs/app/app_q1.svg'
const HaveAnyImagePath1 = '/svgs/app/app_q2.svg'
const SmilePath = '/smile.svg'
const SadPath = '/sad.svg'


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'hidden',
        paddingTop: 64,
        background: '#343234',
        userSelect: "none",
    },
    first: {
        position:"relative",
        backgroundImage: `url(${BackGroundImagePath})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
    },
    title: {
        position: "absolute",
        left: "10%",
        top: "50%",
        color: "black",
        fontSize: "10vh",
        fontWeight: "600",
        opacity: "1",
    },
    subtitle: {
        position: "absolute",
        left: "10%",
        top: "70%",
        color: "black",
        fontSize: "3.5vh",
        fontWeight: "600",
        opacity: "1",
    },
    gogo: {
        position: "absolute",
        left: "10%",
        top: "83%",
        opacity: "1",
    },
    frame: {
        position: "relative",
        height: "100vh",
        background: "#343234",
    },
    secondTitle: {
        position: "relative",
        top: "10%",
        color: "white",
        textAlign: "center",
        fontSize: "10vh",
        fontWeight: "600",
    },
    leftblock: {
        position: "absolute",
        top: "30vh",
        left: "15vw",
        background: "#efcd14",
        height: "55vh",
        width: "30vw",
    },
    leftblockImage: {
        position: "absolute",
        width: "100%",
        height: "70%",
    },
    ImageQQ: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    blockText: {
        position: "absolute",
        top: "75%",
        width: "100%",
        height: "30%",
        textAlign: "center",
        fontWeight: "600",
        fontSize: "3.5vh",
    },
    rightblock: {
        position: "absolute",
        top: "30vh",
        right: "15vw",
        background: "#efcd14",
        height: "55vh",
        width: "30vw",
    },
    rightblockImage: {
        position: "absolute",
        width: "100%",
        height: "70%",
    },
    thirdSquare: {
        position: "absolute",
        left: "15vw",
        top: "10vh",
        width: "20vh",
        height: "20vh",

        display: "flex",
        flexFlow: "column wrap",
        alignItems: "center",
        justifyContent: "center",

        background: "#c5c5c5",
        color: "black",
    },
    thirdSquareText1: {
        fontSize: "3vh",
    },
    thirdSquareText2: {
        fontSize: "5vh",
        fontWeight: "bold",
    },
    thirdTitle: {
        position: "absolute",
        left: "30%",
        top: "15%",

        color: "#c5c5c5",
        fontSize: "5.5vh",
        fontWeight: "bold"
    },
    sad: {
        position: "absolute",
        left: "15vw",
        top: "40vh",
        width: "20vh",
        height: "20vh",
    },
    smile: {
        position: "absolute",
        left: "15vw",
        top: "70vh",
        width: "20vh",
        height: "20vh",
    },
    sadBlock: {
        position: "absolute",
        left: "30%",
        top: "36vh",
        width: "57vw",
        height: "25vh",
        border: "solid #efcd14",
    },
    smileBlock: {
        position: "absolute",
        left: "30%",
        top: "66vh",
        width: "57vw",
        height: "25vh",
        border: "solid #efcd14"
    },
    BlockTop: {
        position: "absolute",
        width: "100%",
        height: "30%",
        background: "#efcd14",

        display: "flex",
        alignItems: "center",
    },
    BlockTopText: {
        fontSize: "4vh",
        paddingLeft: "4vh",
        fontWeight: "600",
    },
    BlockBottom: {
        position: "absolute",
        width: "100%",
        height: "70%",
        top: "30%",

        display: "flex",
        alignItems: "center",
    },
    BlockBottomText: {
        fontSize: "3.6vh",
        paddingLeft: "3.6vh",
        paddingRight:"3.6vh",
        fontWeight: "500",
        color: "#c5c5c5",
    },

}))

const AppDesign = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {/* 我們可以協助您設計整合性系統 */}
            <div className={classes.first}>
                <div className={classes.title}>app應用開發</div>
                <div className={classes.subtitle}>我們可以協助您設計應用程式<br/>增強您的數位化行銷能力</div>
                <div className={classes.gogo} >
                    <Button
                        href="contact" fontSize="2vw"
                        bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">馬上諮詢</Button>
                </div>
            </div>
            {/* 您是否有以下困擾 */}
            <div className={classes.frame}>
                <div className={classes.secondTitle}>
                    您是否有以下困擾?
                </div>
                <div className={classes.leftblock}>
                    <div className={classes.leftblockImage}>
                        <img src={HaveAnyImagePath} alt="" className={classes.ImageQQ} />
                    </div>
                    <div className={classes.blockText}>員工的手機不是<br/>只能拿來看youtube</div>
                </div>
                <div className={classes.rightblock}>
                    <div className={classes.rightblockImage}>
                        <img src={HaveAnyImagePath1} alt="" className={classes.ImageQQ} />
                    </div>
                    <div className={classes.blockText}>你有想過一個app在顧客<br/>手機會帶來多少機會?</div>
                </div>
            </div>
            {/* 市面上的商店POS機不夠客製化 */}
            <div className={classes.frame}>
                <div className={classes.thirdSquare}>
                    <div className={classes.thirdSquareText1}>常見困擾</div>
                    <div className={classes.thirdSquareText2}>1</div>
                </div>
                <div className={classes.thirdTitle}>
                    每次要用電腦好麻煩
                </div>
                <img src={SadPath} className={classes.sad} alt=""/>
                <img src={SmilePath} className={classes.smile} alt=""/>
                <div className={classes.sadBlock}>
                    <div className={classes.BlockTop}>
                        <div className={classes.BlockTopText}>遇到的困擾…</div>
                    </div>
                    <div className={classes.BlockBottom}>
                        <div className={classes.BlockBottomText}>我有些工作需要隨著員工移動，最好是他們可以隨時取得。</div>
                    </div>
                </div>
                <div className={classes.smileBlock}>
                    <div className={classes.BlockTop}>
                        <div className={classes.BlockTopText}>如果交給Threerocks…</div>
                    </div>
                    <div className={classes.BlockBottom}>
                        <div className={classes.BlockBottomText}>我們有可以為你設計一個專門屬於工作用的app，下載到全部員工的手機裡。</div>
                    </div>
                </div>
            </div>
            {/* 使用第三方通訊軟體有資訊安全疑慮 */}
            <div className={classes.frame}>
                <div className={classes.thirdSquare}>
                    <div className={classes.thirdSquareText1}>常見困擾</div>
                    <div className={classes.thirdSquareText2}>2</div>
                </div>
                <div className={classes.thirdTitle}>
                    你有想過一個app在顧客手機會帶來多少影響嗎?
                </div>
                <img src={SadPath} className={classes.sad} alt=""/>
                <img src={SmilePath} className={classes.smile} alt=""/>
                <div className={classes.sadBlock}>
                    <div className={classes.BlockTop}>
                        <div className={classes.BlockTopText}>遇到的困擾…</div>
                    </div>
                    <div className={classes.BlockBottom}>
                        <div className={classes.BlockBottomText}>網站的觸擊率一直不是很高，還有很多限制，甚至還要自己買廣告。</div>
                    </div>
                </div>
                <div className={classes.smileBlock}>
                    <div className={classes.BlockTop}>
                        <div className={classes.BlockTopText}>如果交給Threerocks…</div>
                    </div>
                    <div className={classes.BlockBottom}>
                        <div className={classes.BlockBottomText}>有了APP之後顧客只要拿起手機就可以看到我們的標誌，還可以每天推送通知給顧客，一舉兩得!</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppDesign