import { Button } from "@material-ui/core"
import React from "react"
import styled from 'styled-components'

const InnerMenuButton = styled(Button)`
    font-weight: bold;
    padding: 6px 16px;
    margin-left: 12px;
    border-radius: 1vw;
    font-size: 1vw;
    ${props => props.lit && 'background: #6D6D6D;'}
    &:hover {
        background-color: #${props => props.lit ? '6D6D6D' : 'FECE14'};
    }
`

const MenuButton = props => {
    const { children, href, ...otherProps } = props
    return <InnerMenuButton lit={href === window.location.pathname} href={(href !== window.location.pathname) && href} {...otherProps}>{children}</InnerMenuButton>
}

export default MenuButton