import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#343234',
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        position: "relative",
    },
    headBlock:{
        position:"absolute",
        top:"20%",
        left:"10%",
        display:"inline-flex",
        alignItems: "baseline",
    },
    name: {
        color:"#efcd14",
        fontSize:"11.5vh",
        paddingRight:"1.8rem",
    },
    job: {
        fontSize: "4.5vh",
        color: "#343234",
        textShadow: `1px 1px 0 #efcd14,
           1px -1px 0 #efcd14,
          -1px 1px 0 #efcd14,
           -1px -1px 0 #efcd14`,
    },
    expTitle: {
        position: "absolute",
        top: "38%",
        left: "11%",
        fontSize: "3vh",
        color: "#6c6c6c",
    },
    expContent: {
        position: "absolute",
        top: "43%",
        left: "11%",
        fontSize: "2.5vh",
        color: "white",
        fontWeight: "Medium",
    },
}))

const AboutFrame = (props) => {
    const { children, name, job, stone, portrait , page} = props
    const classes = useStyles()
    return (
        <div className={classes.root} page={page}>
            <div className={classes.headBlock}>
                <div className={classes.name}>
                    {name}
                </div>
                <div className={classes.job}>
                    {job}
                </div>
            </div>
            <div className={classes.expTitle}>
                工作經驗
            </div>
            <div className={classes.expContent}>
                {children}
            </div>
            {stone}
            {portrait}
        </div>
    );
}

export default AboutFrame