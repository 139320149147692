import { AppBar, Link, makeStyles, Toolbar, Typography } from "@material-ui/core"
import React from "react"
import MenuButton from "./MenuButton"

const useStyles = makeStyles(theme => ({
    root: {
        background: '#343234'
    },
    content: {
        padding: `0px ${theme.spacing(1.2)}vw`
    },
    title: {
        display: 'inline-flex',
        alignItems: 'center',
        flexGrow: 1
        ,
        color: 'white'
        ,
        fontFamily: "Times New Roman"
        ,
        '&:hover': {
            textDecoration: 'none'
        }
    }
}))

const Navbar = () => {
    const classes = useStyles()

    return (
        <AppBar position="fixed" className={classes.root} elevation={0}>
            <Toolbar className={classes.content}>
                <Link href='/' className={classes.title}>
                    <img alt="icon" height="48" src='/rocks_dark.png' />
                    <Typography variant="h5">
                        Threerocks Studio
                    </Typography>
                </Link>
                <MenuButton href="/" color="inherit">首頁</MenuButton>
                <MenuButton href="/service" color="inherit">服務項目</MenuButton>
                <MenuButton href="/question" color="inherit">常見問題</MenuButton>
                <MenuButton href="/about" color="inherit">關於我們</MenuButton>
                <MenuButton href="/contact" color="inherit">聯絡我們</MenuButton>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar