import { IconButton, makeStyles, Paper as MuiPaper } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import React, { useState } from 'react'
import styled from 'styled-components'
import CarouselFrame from './CarouselFrame'
import PageButton from './PageButton'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'end',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        marginTop:theme.spacing(4),
    },
    buttons: {
        display: 'inline-flex',
        columnGap: theme.spacing(2)
    },
    prev: {
        color: 'white',
        width: '48px',
        height: '48px'
    },
    prevButton: {
        position: 'absolute',
        top: '40%',
        left: '22%',
        zIndex: 1000
    },
    next: {
        color: 'white',
        width: '48px',
        height: '48px'
    },
    nextButton: {
        position: 'absolute',
        top: '40%',
        right: '22%',
        zIndex: 1000
    }
}))

const Carousel = props => {
    const { children, ...otherProps } = props
    const classes = useStyles()
    const [page, setPage] = useState(0)

    const children_wrapper = props.children.length === undefined ? [children] : props.children
    const N_pages = children_wrapper.length || 1

    const handlePrevPage = () => setPage(prevPage => {
        if (props.continue) {
            if (prevPage === 0) {
                return N_pages - 1
            }
        }
        return Math.max(0, prevPage - 1)
    })
    const handleNextPage = () => setPage(prevPage => {
        if (props.continue) {
            if (prevPage === N_pages - 1) {
                return 0
            }
        }
        return Math.min(N_pages - 1, prevPage + 1)
    })
    return (
        <div className={classes.root} {...otherProps}>
            {children_wrapper.map((child, idx) => <CarouselFrame idx={idx} key={idx} page={page}>{child}</CarouselFrame>)}

            {
                (page !== 0 || props.continue) &&
                <IconButton className={classes.prevButton} onClick={handlePrevPage}>
                    <ArrowBackIos className={classes.prev} viewBox="-4 0 20 24" />
                </IconButton>
            }
            {
                (page !== N_pages - 1 || props.continue) &&
                <IconButton className={classes.nextButton} onClick={handleNextPage}>
                    <ArrowForwardIos className={classes.next} />
                </IconButton>
            }

            {/* Page Buttons */}
            <div className={classes.buttons}>
                {
                    new Array(N_pages).fill(0).map((_, idx) => <PageButton idx={idx} key={idx} active={page === idx} setPage={setPage} />)
                }
            </div>
        </div>
    )
}

const Paper = styled(MuiPaper)`
    height: 100%;
    padding: 48px;
    color: white;
    background: #6d6d6d;
    display: block;
`
export const CarouselPage = (props) => {
    const { children, ...otherProps } = props
    return <Paper elevation={0} {...otherProps}>{children}</Paper>
}
export default Carousel
