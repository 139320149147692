import { Button, makeStyles } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import req from "../../Component/API/api";
import InfoCard from "./Component/infoCard";
import RockStepper from "./Component/rockStepper";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#343234",
        overflowX: "hidden",
        height: "100vh",
        display: "flex",
        flexFlow: 'column nowrap',
        alignItems: "center",
    },
    head: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "700",
        fontSize: "1.3rem",
        paddingRight: "1.7rem",
        gap: "10px",
    },
    orderNum: {
        border: "3px solid #efcd14",
        padding: "0.1rem 1rem",
        margin: "1rem 1rem 3rem 1rem"
        ,
        fontSize: "1.8rem",
        color: "#efcd14",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stepRow: {
        height: "48vh",
        width: "70%",
    },
    taskFirstRow: {
        height: "48vh",
        width: "70%",
        display: "inline-flex",
        alignItems: "stretch",
        marginTop: "5rem",
        gap: "5rem",
    },
    taskSecondRow: {
        marginTop: "5rem",
        marginBottom: "5rem",
        width: "70%",
        display: "inline-flex",
        gap: "5rem",
    },
    footer: {
        marginBottom: "2rem",
        color: "#c5c5c5",
        fontSize: "0.5rem",
        textAlign: "center",
    },
    content: {
        paddingTop: "1.5rem"
    },
    attachments: {
        display: "flex",
        alignItems: "baseline",
        paddingTop: "1.5vh",
    },
    attachmentsContent: {
        display: "inline-flex",
        paddingTop: "1.2vh",
        gap: "1vw",
        marginLeft: "2vw",
    }
}))


const Task = () => {
    const classes = useStyles()
    const { uuid } = useParams()
    const [taskInfo, setTaskInfo] = useState({})
    const [view, setView] = useState(0)

    useEffect(() => {
        req.get(`/task/detail/${uuid}`)
            .then(resp => {
                setTaskInfo(resp.data.data)
                setView(1)
            })
            .catch(err => {
                console.log(err)
                setView(-1)
            })
    }, [])

    if (view === 0) {
        return (
            <div className={classes.root}>
                <Button className={classes.head} href="/">
                    <img alt="icon" height="70" src='/rocks_dark.png' />
                    <p>Threerocks Studio</p>
                </Button>
                <CircularProgress />
            </div>
        )
    }

    if (view === -1) {
        return (
            <div className={classes.root}>
                <Button className={classes.head} href="/">
                    <img alt="icon" height="70" src='/rocks_dark.png' />
                    <p>Threerocks Studio</p>
                </Button>
                <div style={{ color: "white", fontSize: "1.5vh" }}>這個UUID不存在</div>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            {/* HEAD */}
            <Button className={classes.head} href="/">
                <img alt="icon" height="70" src='/rocks_dark.png' />
                <p>Threerocks Studio</p>
            </Button>
            <div className={classes.orderNum}>
                訂單編號：{taskInfo.uuid}
            </div>

            <div className={classes.stepRow}>
                <RockStepper tstate={taskInfo.tstate} />
            </div>

            {/* CARDS */}
            <div className={classes.taskFirstRow}>
                <InfoCard title="聯絡資訊">
                    <div>姓氏：{taskInfo.first_name}</div>
                    <div>名字：{taskInfo.last_name}</div>
                    <div>性別：{taskInfo.gender}</div>
                    <div>手機：{taskInfo.phone}</div>
                    <div>信箱：{taskInfo.mail}</div>
                </InfoCard>
                <InfoCard title="委託資訊">
                    <div>工作單位：{taskInfo.unit}</div>
                    <div>職稱：{taskInfo.job}</div>
                    <div>預算：{taskInfo.budget}</div>
                    <div>服務種類：{taskInfo.category}</div>
                    <div>期望交件日期：{taskInfo.deadline}</div>
                    <div>方便聯絡時間：{taskInfo.contact}</div>
                </InfoCard>
            </div>
            <div className={classes.taskSecondRow}>
                <InfoCard title="委託內容" fill>
                    <div>
                        委託標題：{taskInfo.title}
                    </div>
                    <div style={{ whiteSpace: 'pre-wrap' }} className={classes.content}>
                        內文：{taskInfo.content}
                    </div>
                    <div className={classes.attachments}>
                        其他附件：
                        <div className={classes.attachmentsContent}>
                            {taskInfo.attachments.map((file) => {
                                return <a
                                    href={`https://threerocks.work/api/download/${file.path}`}
                                    download={file.name}
                                    style={{ textDecoration: "none" }}>
                                    <Chip clickable label={file.name} />
                                </a>
                            })}
                        </div>
                    </div>
                </InfoCard>
            </div>
            <div className={classes.footer}>
                <div>如有任何疑問，請盡速聯繫工作室</div>
                <div>studio@threerocks.work</div>
            </div>
        </div>
    )
}

export default Task;