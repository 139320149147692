import { makeStyles } from "@material-ui/core";
import React from "react";
import Button from "../../Component/Button";
import Expander from "../../Component/Expander";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'hidden',
        paddingTop: 64,
        background: '#343234'
        ,
        minHeight:"100vh",
    },
    title: {
        top: "12vh",
        textAlign: "center",
        color: "#efcd14",
        fontSize: "4.8vh",
    },
    bottom: {
        marginTop: "3vh",
        textAlign: "center",
    },
    container: {
        top: "15vh",
        margin: "0 auto 0 auto",
        width: "60%",
    }
}))


const element = [
    {
        title: "填完表單後要怎麼知道後續?",
        content: "在您填完表單時會寄一封email到您的信箱中，其中會包含一個可以看到進度的連結。送出後的三個工作天裡會有工作室成員向您聯絡，內容會包含我們初步的可行性評估以及詳細的時程。",
        // button: {
        //     text: "詳細說明",
        //     url: "#",
        // }
    },
    {
        title: "送出表單後我該怎麼更改內容?",
        content: "您可以直接寄信到studio@threerocks.work並附上您的訂單編號，我們會手動處理您的要求。",
    },
    {
        title: "我該怎麼預估預算範圍?",
        content: "預算範圍會隨著工作內容以及交件時間有很大的差異。",
    },
    {
        title: "談妥後經額怎麼交付?",
        content: "依照預算的不同會有不同的支付方式，新台幣三萬以內的委託可以在完成後全額交付。超過三萬的委託會需要預付20%的訂金作為雙方的保證。如果是包含設計類型的委託，經過出版討論後，不論最後有沒有採用，都需要支付設計的相關費用。",
    },
    {
        title: "我不確定我的需求屬於哪種類型，我該怎麼詢問?",
        content: "可以直接寄信到studio@threerocks.work我們會有專人回覆您。",
    },
    {
        title: "關於專案的後續維護與調整會怎麼算？",
        content: "基本上專案完成後如果不是大型的調整我們都會免費的提供相關服務，如果是需要整體架構的調整這類的大型更動，我們會再開一個新的委託來處理，不過基本上報價一定也會比原本的便宜。",
    },
]

const Question = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                常見問題
            </div>

            <div className={classes.container}>
                {
                    element.map((item, idx) => {
                        return (
                            <Expander key={idx} title={item.title}>
                                <div>{item.content}</div>
                                {
                                    item.hasOwnProperty("button") && <Button
                                        href={item.button.url} fontSize="1.2vh"
                                        bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">{item.button.text}</Button>
                                }
                            </Expander>
                        )
                    })
                }
            </div>
           
            <div className={classes.bottom}>
                <Button
                    href="contact" fontSize="1.6vw"
                    bgcolor="#efcd14" fgcolor="black" hcolor="#b89d00">馬上諮詢</Button>
            </div>
        </div>
    )
}

export default Question