import React from 'react'
import styled from 'styled-components'

const Frame = styled.div`
    position: absolute;
    width: 44%;
    height: 70%;

    top: 0;
    ${props => props.idx === props.page && 'left: 28%;'}
    ${props => props.idx < props.page && 'left: 0;'}
    ${props => props.idx > props.page && 'left: 56%;'}

    z-index: ${props => props.idx === props.page ? 500 : 0};

    opacity: ${props => props.idx === props.page ? 1 : (Math.abs(props.page - props.idx) === 1 ? 0.3 : 0)};
    transform: scale(${props => props.idx === props.page ? 1 : 0.8});

    transition: left 0.3s, opacity 0.3s, transform 0.3s;
`

const CarouselFrame = props => {
    const { children, ...otherProps } = props
    return <Frame {...otherProps}>{children}</Frame>
}

export default CarouselFrame
