import { Avatar, Grid, makeStyles, styled } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import { mdiApplicationBracketsOutline, mdiCellphone, mdiCogs, mdiHeadSnowflake, mdiMemory } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from "react";
import Button from "../../Component/Button";
import Carousel, { CarouselPage } from "./Component/Carousel";
import PageFrame from "./Component/PageFrame";
import ServiceCard from "./Component/ServiceCard";
import "./animate.css"
import Footer from "../../Component/footer";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'hidden',
    paddingTop: 64
    ,
    background: '#343234'
  },
  slogan: {
    position: "absolute",
    bottom: "15%",
    left: "15%",
  },
  sloganText: {
    color: "#FECE14",
    fontWeight: "bold",
    fontSize: "4.5vw",
    marginBottom: theme.spacing(4),
    userSelect: "none",
  },
  sloganSecond: {
    position: "absolute",
    top: "10%",
    left: "10%",
    userSelect: "none",
  },
  sloganSecondText: {
    color: "#FECE14",
    fontWeight: "bold",
    fontSize: "4.5vw",
    marginBottom: theme.spacing(2),
    userSelect: "none",
  },
  ContentSecond: {
    color: "white",
    fontSize: "2vw",
    position: "absolute",
    left: "50%",
    bottom: "18%",
    userSelect: "none",
  },
  SuspendButton: {
    position: "fixed",
    right: theme.spacing(4),
    bottom: theme.spacing(6),
    zIndex: 1000,
    background: '#efcd14',
    '&:hover': {
      color: 'white',
      background: '#a78f0e'
    },
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
  },
  GridContainer: {
    padding: `${theme.spacing(12)}px ${theme.spacing(12)}px`,
    height: 'calc(100% - 96px)'
  },
  StepsGridContainer: {
    padding: `${theme.spacing(18)}px ${theme.spacing(12)}px`,
    height: 'calc(100% - 96px)'
  },
  GridLayout: {
    alignItems: "center",
    height: '100%'
  },
  GridLayout2: {
    alignItems: "center",
    height: '80%'
  },
  GridItem: {
    height: '100%'
  },
  Service: {
    color: "white",
    fontSize: "3.3vw",
    fontWeight: "bold",
    lineHeight: "3.3vw",
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    userSelect: "none",
  },
  OurServices: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",

    color: "white",
    fontSize: "3.3vw",
    fontWeight: "bold",
  },
  OurServicesElement: {
    lineHeight: 5,
  },
  ServiceIcon: {
    width: '40%',
    // height: '96px',
    margin: theme.spacing(4),
    color: 'white'
  },
  Circle: {
    zIndex: "1",
    height: "7vw",
    width: "7vw",
    borderRadius: "50%",
    backgroundColor: "#efcd14"
    ,
    fontSize: "3.5vw",
    color: "#343234",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Line: {
    position: "relative",
    top: "50%",
    width: "100%",
    borderBottom: "0.8rem  solid #efcd14",
  },
  StepsArrow: {
    width: "3.5vw",
    height: "3.5vw",
  },
  StepsTitle: {
    height: "12vh",
    fontSize: "3.6vw",
    color: "white",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  StepsContent: {
    width: "17vw",
    marginTop: "1.7vw",
    fontSize: "1.5vw",
    color: "#c5c5c5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "justify",
  },
  CarouselContainer: {
    height: `calc(100% - ${theme.spacing(16)}px)`,
    padding: `${theme.spacing(8)}px ${theme.spacing(12)}px`
  },
  CarouselHead: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    padding: theme.spacing(3),
    display: 'inline-flex'
  },
  CarouselAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: theme.spacing(8)
  },
  CarouselInfo: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    fontSize: '2.4vw',
    fontWeight: 700,
  },
  CarouselContent: {
    paddingTop: "3.6rem",
    width: '100%',
    color: '#efcd14',
    fontSize: "1.4vw",
    fontWeight: 700,
    textAlign: "justify",
  },
  RatingUser: {
    display: "inline-flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
}))


const Homepage = () => {
  const classes = useStyles()
  const [activeCard, setActiveCard] = useState(null)

  const handleCardActive = cardId => setActiveCard(cardId)
  document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + (1 / window.devicePixelRatio));
  return (
    <div className={classes.root}>
      {/* <Fab className={classes.SuspendButton}>
        <Chat />
      </Fab> */}

      {/* Page 1 */}
      <PageFrame>
        <img src="/rock_top.svg" alt="rock_top" class="rockTop" />
        <img src="/rock_middle.svg" alt="rock_middle" class="rockMiddle" />
        <img src="/rock_bottom.svg" alt="rock_bottom" class="rockBottom" />
        <div class="slogan">
          <div className={classes.sloganText}>Rocks out of the water</div>
          <Button href="/about"
            bgcolor="#6D6D6D" fgcolor="white" fontSize="2.0vw">了解三顆石頭</Button>
        </div>
      </PageFrame>

      {/* Page 2 */}


      {/* Page 3 */}
      <PageFrame>
        <div className={classes.OurServices}>
          <div className={classes.OurServicesElement}>Our Services</div>
          <div >
            <Button fgcolor="black" bgcolor="#F1CE14" hcolor="#a8900e"
              href="/service" fontWeight="bold" fontSize="2.1vw">深入了解</Button></div>
        </div>
      </PageFrame>

      {/* Page 4 */}
      <PageFrame>
        <div className={classes.GridContainer}>
          <Grid container spacing={4} className={classes.GridLayout}>
            <Grid item xs={4} className={classes.GridItem}>
              <ServiceCard title="網頁設計與部屬" icon={<Icon path={mdiApplicationBracketsOutline} className={classes.ServiceIcon} />}
                cardId={1} handleCardActive={handleCardActive} active={activeCard === 1}
                tag="#商業網站設計、網頁伺服器架設、前後端設計">
                常常覺得為什麼競爭對手的曝光率永遠比自己高嗎？研究顯示一個好的門面會決定顧客對於企業整體形象的80%，一個好的網站就顯得極其重要。
              </ServiceCard>
            </Grid>
            <Grid item xs={4} className={classes.GridItem}>
              <ServiceCard title="自動化流程" icon={<Icon path={mdiMemory} className={classes.ServiceIcon} />}
                cardId={2} handleCardActive={handleCardActive} active={activeCard === 2}
                tag="#自動化工具設計與開發">
                每次都要重複一樣的步驟很煩嗎？浪費人力資源的同時也是增加出錯的機率。自動化流程可以讓整體更有效率，節省公司人力資源完成，最小化出錯機率。
              </ServiceCard>
            </Grid>
            <Grid item xs={4} className={classes.GridItem}>
              <ServiceCard title="app應用開發" icon={<Icon path={mdiCellphone} className={classes.ServiceIcon} />}
                cardId={3} handleCardActive={handleCardActive} active={activeCard === 3}
                tag="#ios app開發、android平台開發、網頁型app開發">
                為了確保顧客的第一選擇就是你，許多店家會發行會員卡。但在這手機不離身得時代，有一個app在手機裡就相當於一張24小時的會員卡直接給顧客。
              </ServiceCard>
            </Grid>
          </Grid>
        </div>
      </PageFrame>

      {/* Page 4.5 */}
      <PageFrame>
        <div  className={classes.OurServices}>
          <Button fgcolor="black" bgcolor="#F1CE14" hcolor="#a8900e"
            href="/contact" fontWeight="bold" fontSize="2.1vw">馬上諮詢</Button></div>
      </PageFrame>

      {/* Page 5 */}
      < PageFrame >
        <div className={classes.StepsGridContainer}>
          <Grid container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Grid container
              direction="row"
              justifyContent="space-around"
              alignItems="center">
              <div className={classes.Circle}>01</div>
              {/* <ArrowForwardIosIcon className={classes.StepsArrow} /> */}
              <div className={classes.Circle}>02</div>
              {/* <ArrowForwardIosIcon className={classes.StepsArrow} /> */}
              <div className={classes.Circle}>03</div>
            </Grid>
            <Grid container
              direction="row"
              justifyContent="space-around"
              alignItems="center">
              <div className={classes.StepsTitle}>諮詢</div>
              {/* <div className={classes.StepsArrow}></div> */}
              <div className={classes.StepsTitle}>執行</div>
              {/* <div className={classes.StepsArrow}></div> */}
              <div className={classes.StepsTitle}>結案</div>
            </Grid>
            <Grid container
              direction="row"
              justifyContent="space-around"
              alignItems="baseline">
              {/* 諮詢 */}
              <div className={classes.StepsContent}>
                藉由網站的表單或是email向我們提出問題，我們會在經過可行性評估後向您回報我們認為最佳的解決方案與詳細過程，設計類委託會提出構想與草稿編排。整個評估過程最長會落在三天內就會完成，這個階段將不會與您收取任何費用。
              </div>
              {/* 執行 */}
              <div className={classes.StepsContent}>
                雙方確定解決方案和合約內容後就會由工作室開始執行，會有專門的窗口向您更新進度，有任何問題也都可以向我們詢問。設計類型的委託會有需要多版的確認，為保障雙方的權益，初版的製作會需要支付訂金才會開始執行。
              </div>
              {/* 結案 */}
              <div className={classes.StepsContent}>
                當工作室完成所有需求後會與您進行一次全面的結案報告。在雙方取得共識後就會正式結案。此時才會需要支付剩餘款項。
              </div>
            </Grid>
          </Grid>
        </div>
      </PageFrame >

      {/* Page 6 */}
      < PageFrame >
        <div className={classes.CarouselContainer}>
          <Carousel>
            <CarouselPage>
              <div className={classes.CarouselHead}>
                {/* <Avatar alt="Alan" src="https://mymodernmet.com/wp/wp-content/uploads/2019/09/100k-ai-faces-5.jpg" className={classes.CarouselAvatar} /> */}
                <div className={classes.CarouselInfo}>
                  <div className={classes.RatingUser}>台北上市公司 吳老闆</div>
                  <div >
                    <Rating name="read-only" value="4.5" size="large" readOnly />
                  </div>
                </div>
              </div>
              <div className={classes.CarouselContent}>
                我之前一直在想為什麼我們的東西明比對手好用也更便宜，
                但銷量卻一直起不來。我找上Threerocks幫我做一下分析，
                發現是我們的網頁設計不夠親民，民眾沒辦法很直觀的體驗
                我們產品的優勢在哪裡。於是我抱持著試一試的心態，請他
                們來改造看看。沒想到新版網站上市後，我的整體銷量不斷
                上升，網站的點擊率也飄升到之前的五倍。
              </div>
            </CarouselPage>
            <CarouselPage>
              <div className={classes.CarouselHead}>
                {/* <Avatar alt="Alan" src="https://mymodernmet.com/wp/wp-content/uploads/2019/09/100k-ai-faces-4.jpg" className={classes.CarouselAvatar} /> */}
                <div className={classes.CarouselInfo}>
                  <div className={classes.RatingUser}>台南淨水器公司  周老闆</div>
                  <div >
                    <Rating name="read-only" value="4.5" size="large" readOnly />
                  </div>
                </div>
              </div>
              <div className={classes.CarouselContent}>
                公司每個禮拜都有很多來自總公司的更新
                需要安裝到新的電腦上，在以往我們都需要一個
                人全職來負責這件事，有時候甚至做不完。但在
                我們委託Threerocks製作自動更新軟體後，我們
                的員工只需要一個按鍵就可以完成所有更新，還
                會自動驗證他的正確性。幫我省下許多的人力成本。
              </div>
            </CarouselPage>

          </Carousel>
        </div>
      </PageFrame >
      <Footer />
    </div >
  )
}

export default Homepage